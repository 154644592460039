.checklist-wrapper {
    padding-top: 82px;
    font-family: 'Poppins', sans-serif;
}

.checklist-text {
    font-weight: bold;
    font-size: 24px;
}

.checklist-btn {
    width: 87vw;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(72, 72, 226);
    margin-top: 8px;
    cursor: pointer;
}

.manage-cars-btn {
    width: 87vw;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(72, 72, 226);
    margin-top: 14px;
    cursor: pointer;
    margin-bottom: 15px;
}

.link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
