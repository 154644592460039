.css-26l3qy-menu, .chakra-select option {
    background: #333 !important;
    color: white !important;
}

.css-1s2u09g-control, .css-1s2u09g-control:hover, .css-1s2u09g-control:focus, .css-1s2u09g-control:active, .select-searchable {
    width: 87vw !important;
}

.css-1s2u09g-control {
    background: transparent !important;
    color: white !important;
}

.css-qc6sy-singleValue {
    color: white !important;
}