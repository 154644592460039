.employees-wrapper {
    padding-top: 75px;
}

.employee-card-wrapper {
    width: 85vw;
    height: 170px;
    border-radius: 6px;
    background: #151b22;
    padding-left: 25px;
    padding-top: 15px;
    margin-top: 12px;
}

.settings {
    position: absolute;
    margin-top: 10px;
    right: 45px;
}

.edit-employee-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    p {
        align-self: flex-start;
        margin-left: 24px;
        margin-bottom: 2px;
    }

    .general-input, select {
        background: transparent;
        outline: none;
        border: 1px solid white;
        padding-left: 15px;
        border-radius: 6px;
        height: 40px;
        width: 90vw;
        color: white;
        margin-bottom: 12px;
    }

    .edit-employee-text {
        font-weight: bold;
        font-size: 21px;
        margin-bottom: 22px;
        align-self: center;
        margin-left: 0px;
    }
}

.button-edit {
    border-radius: 6px;
    height: 40px;
    width: 90vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.revoke {
    background: rgb(214, 88, 88);
}

.save {
    background: rgb(72, 72, 226);
}
