.car-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    padding-top: 75px;
}

.car-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 87vw;
    height: 140px;
    background: #151b22;
    margin-top: 10px;
    padding-left: 25px;
    border-radius: 6px;
}

.delete-car-btn {
    position: absolute;
    right: 45px;
    margin-top: -22%;
    width: 80px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(206, 72, 72);
}
