@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #10151A;
  color: white;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: white;
}

option {
  color: black;
}
.navigation-wrapper{height:100vh;background:#161D23;display:flex;flex-direction:column;justify-content:center;align-items:center;position:absolute !important;z-index:9999;top:0px;left:0px}.nav-bigger{height:120vh}.black-lines{background:#333 !important}.navigation-text{font-weight:bold;font-family:"Poppins";margin-top:25px;font-size:25px;text-decoration:none;color:white}.hamburger-wrapper{position:absolute;z-index:10000;margin-top:25px;margin-left:10px;cursor:pointer}.ham-line,.ham-line-close{width:52px;height:7px;background:white;border-radius:360px;margin-top:5px}.ham-wrap-main{margin-top:30px}.close-ham-wrapper{padding-top:15px}.line1-close{-webkit-transform:rotate(45deg);transform:rotate(45deg);position:absolute}.line2-close{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}.open-ham-wrapper-jobs{margin-top:20px}

.checklist-wrapper{padding-top:82px;font-family:'Poppins', sans-serif}.checklist-text{font-weight:bold;font-size:24px}.checklist-btn{width:87vw;height:40px;border-radius:6px;display:flex;justify-content:center;align-items:center;background:#4848e2;margin-top:8px;cursor:pointer}.manage-cars-btn{width:87vw;height:40px;border-radius:6px;display:flex;justify-content:center;align-items:center;background:#4848e2;margin-top:14px;cursor:pointer;margin-bottom:15px}.link{width:100%;height:100%;display:flex;justify-content:center;align-items:center}

.login-page{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100vh}.login-input,.login-button{width:85vw;height:40px;border-radius:7px}.login-input{outline:none;background:transparent;border:1px solid white;padding-left:20px;color:white;margin-top:6.4px}.login-button{margin-top:23px;display:flex;justify-content:center;align-items:center;border:none}.error-wrapper{width:85vw;height:80px;border-radius:6px;background:#d43a3a;color:white;font-family:'Poppins', sans-serif;font-weight:bold;font-size:14px;padding-left:20px;padding-right:20px;display:flex;justify-content:center;align-items:center;margin-bottom:50px}

.e-schedule{background:#333 !important;color:white !important;width:100vw !important;margin-left:-15px}.e-date-time{margin-top:60px}.content-text,.e-date-time-details,.e-description-details{font-size:16px !important;font-family:'Poppins', sans-serif}.e-description-details{margin-bottom:10px}.content-text{margin-left:30px;margin-top:5px}.e-description-textarea{height:160px !important}

.stock-cards-wrapper{display:flex;flex-direction:column;justify-content:center;align-items:center;padding-top:20%}.stock-item-card{width:90vw;height:140px;border-radius:6px;background:#171E24;display:flex;justify-content:center;align-items:center;margin-top:10px}.stock-name,.stock-amount{font-size:18px;font-family:'Poppins', sans-serif}.stock-right-section{margin-top:-8px}.stock-right-section button{width:30vw;height:34px;font-family:'Poppins', sans-serif;display:flex;justify-content:center;align-items:center;margin-left:35px;margin-top:8px;cursor:pointer}.stock-right-section button a{width:100%;height:100%;display:flex;justify-content:center;align-items:center}.add-stock-button{width:100px;height:35px;border-radius:6px;background:white;color:black;position:absolute;right:35px;top:25px;display:flex;justify-content:center;align-items:center;cursor:pointer}.add-stock-button a{text-decoration:none;color:black}.trash{position:absolute;right:32px;margin-top:-22%;cursor:pointer}

.add-stock-screen{display:flex;justify-content:center;align-items:center;flex-direction:column;height:100vh}.add-stock-text{margin-top:40px;font-weight:bold;font-family:'Poppins', sans-serif;font-size:24px;margin-bottom:12px}a{text-decoration:none;color:white}.add-stock-input{width:85vw;height:40px;outline:none;background:none;color:white;border:1px solid white;padding-left:18px;border-radius:8px;margin-top:8px}.add-stock-btn{width:85vw;height:40px;border-radius:6px;outline:none;border:none;display:flex;justify-content:center;align-items:center;font-weight:500;font-family:'Poppins', sans-serif;font-size:15px;color:white}.add-stock-delete{margin-top:24px;background:#dd5454}.add-stock-finish{margin-top:8px;background:#4848e2}.add-stock-cancel{margin-top:8px;background:#1d2630}.add-stock-cancel-text{width:100%;height:100%;display:flex;justify-content:center;align-items:center}

.css-26l3qy-menu,.chakra-select option{background:#333 !important;color:white !important}.css-1s2u09g-control,.css-1s2u09g-control:hover,.css-1s2u09g-control:focus,.css-1s2u09g-control:active,.select-searchable{width:87vw !important}.css-1s2u09g-control{background:transparent !important;color:white !important}.css-qc6sy-singleValue{color:white !important}

.check-wrapper{font-family:'Poppins', sans-serif;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;height:100vh;padding-left:20px;padding-right:20px}.check-wrapper .warning-general{font-size:14px;color:#c53f3f}.check-wrapper .checklist-btn{margin-top:22px}.checklist-type-text{font-weight:bold;font-size:22px}.warning-checklist{font-size:17px;margin-top:10px;margin-bottom:15px}.toolcard-box{width:86vw;height:130px;border-radius:6px;background:#1d272f;margin-top:10px;display:flex;justify-content:center;align-items:center;flex-direction:column}.check-action-wrapper{display:flex;justify-content:space-evenly;width:100%}.check-action{height:40px;width:70px;border-radius:6px;background:#25323c;display:flex;justify-content:center;align-items:center;cursor:pointer;margin-top:8px}.white{background:white}

.employees-wrapper{padding-top:75px}.employee-card-wrapper{width:85vw;height:170px;border-radius:6px;background:#151b22;padding-left:25px;padding-top:15px;margin-top:12px}.settings{position:absolute;margin-top:10px;right:45px}.edit-employee-wrapper{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100vh}.edit-employee-wrapper p{align-self:flex-start;margin-left:24px;margin-bottom:2px}.edit-employee-wrapper .general-input,.edit-employee-wrapper select{background:transparent;outline:none;border:1px solid white;padding-left:15px;border-radius:6px;height:40px;width:90vw;color:white;margin-bottom:12px}.edit-employee-wrapper .edit-employee-text{font-weight:bold;font-size:21px;margin-bottom:22px;align-self:center;margin-left:0px}.button-edit{border-radius:6px;height:40px;width:90vw;color:white;display:flex;justify-content:center;align-items:center;margin-top:8px}.revoke{background:#d65858}.save{background:#4848e2}

.add-tools-screen{display:flex;flex-direction:column;height:100vh;justify-content:center;align-items:center}.add-tools-screen input,.add-tools-screen select{width:90vw;height:40px;border-radius:6px;outline:none;background:transparent;border:1px solid white;color:white;padding-left:20px;margin-bottom:6px}.add-tools-screen input[placeholder="Amount"]{margin-bottom:40px}.add-tools-screen option{color:black}.btn-general-add-tool{width:90vw;height:40px;border-radius:6px;margin-top:8px;outline:none;border:none;color:white;font-weight:600;font-size:16px}.add{background:#4848e2}.cancel{background:#334252}

.container-license{display:flex;width:100vw;flex-direction:row;padding-top:100px}.container-license p{margin-left:40px;padding-bottom:5px;cursor:pointer}.container-cards{display:flex;width:100%;flex-direction:column;align-items:center}.car{padding:10px 20px}.active-car{background:#4848e2;border-radius:6px}.tool-card{margin-top:18px;width:90vw;height:150px;background:#151b22;display:flex;justify-content:center;flex-direction:column;padding-left:25px}.tool-card p{font-size:16.6px}.tool-card b{font-size:18px}.add-tool-btn{width:110px;height:35px;border-radius:6px;background:white;color:black;display:flex;justify-content:center;align-items:center;right:35px;top:30px;position:absolute}.settings-toolcard{position:absolute;margin-top:-24%;right:35px}.dropdown-toolcard{width:100px;height:100px;border-radius:6px 0px 6px 6px;background:#202933;position:absolute;margin-top:5%;right:35px}.dropdown-toolcard p{color:white;display:flex;justify-content:center;align-items:center;z-index:5;height:50%;width:100%;cursor:pointer}

.car-container{display:flex;justify-content:center;align-items:center;flex-direction:column;width:100vw;padding-top:75px}.car-card{display:flex;justify-content:center;flex-direction:column;width:87vw;height:140px;background:#151b22;margin-top:10px;padding-left:25px;border-radius:6px}.delete-car-btn{position:absolute;right:45px;margin-top:-22%;width:80px;height:30px;border-radius:6px;display:flex;justify-content:center;align-items:center;background:#ce4848}

.wrapper-createInvoice{width:100vw;height:100vh;overflow:hidden;background:#10151A}.wrapper-createBox{height:90%;width:100%;position:absolute;bottom:0;background:#EAE7EA;border-radius:0 30px 0 0;font-family:'Outfit', sans-serif}.wrapper-createBox::before{content:"";position:absolute;height:40px;width:50%;background:#EAE7EA;top:-38px;border-top-left-radius:30px;border-top-right-radius:5px}.wrapper-createBox::after{content:"";position:absolute;height:40px;width:25px;background:#EAE7EA;top:-38px;left:47%;-webkit-transform:skewX(30deg);transform:skewX(30deg);border-top-left-radius:5px;border-top-right-radius:5px}.add-invoice-item-btn{width:130px;height:40px;border-radius:6px;background:teal;color:white;font-weight:bold;display:flex;justify-content:center;align-items:center;margin-top:10px}

.MuiInputLabel-formControl,.MuiInputBase-input{color:white !important}.MuiInput-underline:before,.MuiInput-underline:after{border-bottom:1px solid white !important;width:150px !important}.MuiFormControl-marginNormal{width:150px}.css-5h82c4{-webkit-padding-start:none !important;padding-inline-start:none !important;padding-left:10px !important}

