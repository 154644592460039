.container-license {
    display: flex;
    width: 100vw;
    flex-direction: row;
    padding-top: 100px;

    p {
        margin-left: 40px;
        padding-bottom: 5px;
        cursor: pointer;
    }
}

.container-cards {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.car {
    padding: 10px 20px;
}

.active-car {
    background: rgb(72, 72, 226);
    border-radius: 6px;
}

.tool-card {
    margin-top: 18px;
    width: 90vw;
    height: 150px;
    background: #151b22;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 25px;

    p {
        font-size: 16.6px;
    }

    b {
        font-size: 18px;
    }
}

.add-tool-btn {
    width: 110px;
    height: 35px;
    border-radius: 6px;
    background: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 35px;
    top: 30px;
    position: absolute;
}

.settings-toolcard {
    position: absolute;
    margin-top: -24%;
    right: 35px;
}

.dropdown-toolcard {
    width: 100px;
    height: 100px;
    border-radius: 6px 0px 6px 6px;
    background: #202933;
    position: absolute;
    margin-top: 5%;
    right: 35px;

    p {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
        height: 50%;
        width: 100%;
        cursor: pointer;
    }
}
