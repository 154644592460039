@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-buttons/styles/material.css";
@import "@syncfusion/ej2-calendars/styles/material.css";
@import "@syncfusion/ej2-dropdowns/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/material.css";
@import "@syncfusion/ej2-lists/styles/material.css";
@import "@syncfusion/ej2-navigations/styles/material.css";
@import "@syncfusion/ej2-popups/styles/material.css";
@import "@syncfusion/ej2-splitbuttons/styles/material.css";
@import "@syncfusion/ej2-react-schedule/styles/material.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #10151A;
  color: white;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: white;
}

option {
  color: black;
}