.MuiInputLabel-formControl, .MuiInputBase-input {
    color: white !important;
}

.MuiInput-underline:before, .MuiInput-underline:after {
    border-bottom: 1px solid white !important;
    width: 150px !important;
}

.MuiFormControl-marginNormal {
    width: 150px;
}

.css-5h82c4 {
    -webkit-padding-start: none !important;
    padding-inline-start: none !important;
    padding-left: 10px !important;
}