.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-input, .login-button {
    width: 85vw;
    height: 40px;
    border-radius: 7px;
}

.login-input {
    outline: none;
    background: transparent;
    border: 1px solid white;
    padding-left: 20px;
    color: white;
    margin-top: 6.4px;
}

.login-button {
    margin-top: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.error-wrapper {
    width: 85vw;
    height: 80px;
    border-radius: 6px;
    background: rgb(212, 58, 58);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
