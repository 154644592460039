.stock-cards-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20%;
}

.stock-item-card {
    width: 90vw;
    height: 140px;
    border-radius: 6px;
    background: #171E24;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.stock-name, .stock-amount {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
}

.stock-right-section {
    margin-top: -8px;
    button {
        width: 30vw;
        height: 34px;
        font-family: 'Poppins', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 35px;
        margin-top: 8px;
        cursor: pointer;

        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.add-stock-button {
    width: 100px;
    height: 35px;
    border-radius: 6px;
    background: white;
    color: black;
    position: absolute;
    right: 35px;
    top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    a {
        text-decoration: none;
        color: black;
    }
}

.trash {
    position: absolute;
    right: 32px;
    margin-top: -22%;
    cursor: pointer;
}
