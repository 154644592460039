.navigation-wrapper {
    height: 100vh;
    background: #161D23;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    z-index: 9999;
    top: 0px;
    left: 0px;
}

.nav-bigger {
    height: 120vh;
}

.black-lines {
    background: #333 !important;
}

.navigation-text {
    font-weight: bold;
    font-family: "Poppins";
    margin-top: 25px;
    font-size: 25px;
    text-decoration: none;
    color: white;
}

.hamburger-wrapper {
    position: absolute;
    z-index: 10000;
    margin-top: 25px;
    margin-left: 10px;
    cursor: pointer;
}

.ham-line, .ham-line-close {
    width: 52px;
    height: 7px;
    background: white;
    border-radius: 360px;
    margin-top: 5px;
}

.ham-wrap-main {
    margin-top: 30px;
}

.close-ham-wrapper {
    padding-top: 15px;
}

.line1-close {
    transform: rotate(45deg);
    position: absolute;
}
.line2-close {
    transform: rotate(-45deg);
}

.open-ham-wrapper-jobs {
    margin-top: 20px;
}
